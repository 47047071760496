button {
  background-color: rgb(220, 221, 224);
  border: none;
  color: rgb(150, 25, 150);
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
}